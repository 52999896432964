import { Button } from "@chakra-ui/button"
import { Box } from "@chakra-ui/layout"
import { Link } from "gatsby"
import { Flex } from "@chakra-ui/layout"
import { Image } from "@chakra-ui/react"
import { Select } from "@chakra-ui/select"
import React from "react"
import { VOMUX_BLUE } from "../landing/colors"
import vomuxIcon from "../../images/largeIcon.png"

const InterviewSessionHeader = props => {
  return (
    <Box borderBottom="2vh" borderBottomColor="black.400" {...props}>
      <Flex w="90vw" justifyContent="space-between">
        <Select
          bg={VOMUX_BLUE}
          color="white"
          fontFamily="monospace"
          mt="1.5vh"
          ml="5vw"
          h="6vh"
          w="25vw"
          onChange={props.handleLanguageChange}
        >
          {Object.keys(props.languages).map(key => {
            return (
              <option style={{ backgroundColor: VOMUX_BLUE }} value={`${key}`}>
                {props.languages[key]}
              </option>
            )
          })}
        </Select>
        <Link to="/home">
          <Image
            mt="1.5vh"
            mr="16vw"
            src={vomuxIcon}
            h="5vh"
            w="5vh"
            cursor="pointer"
          />
        </Link>
        <Button
          mt="1.5vh"
          mr="-4vw"
          color="white"
          fontFamily="monospace"
          bg={VOMUX_BLUE}
        >
          Run Code
        </Button>
      </Flex>
    </Box>
  )
}

export default InterviewSessionHeader
