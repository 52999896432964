import React, { useEffect, useState } from "react"

import Editor, { useMonaco } from "@monaco-editor/react"
import ReactTerminal from "react-terminal-component"

import LANGUAGES from "../config/languages"
import InterviewSessionHeader from "./interview_session_header"
import { VStack, Box } from "@chakra-ui/layout"
import { VOMUX_BLUE, VOMUX_PURPLE } from "../landing/colors"

const InterviewSession = props => {
  const monaco = useMonaco()

  useEffect(() => {
    if (monaco) {
      console.log("here is the monaco isntance:", monaco.editor)
    }
  }, [monaco])

  const [codeText, setCodeText] = useState("")
  const [language, setLanguage] = useState("python")

  function handleEditorChange(value, change) {
    setCodeText(value)
    console.log(`model value: ${value} and change: ${JSON.stringify(change)}`)
    console.log(monaco.editor)
  }

  function handleLanguageChange(event) {
    if (!event) {
      return
    }
    setLanguage(event.target.value)
  }

  return (
    <Box bg={VOMUX_PURPLE}>
      <InterviewSessionHeader
        bg="white"
        h="9vh"
        handleLanguageChange={handleLanguageChange}
        languages={LANGUAGES}
      />

      <VStack h="120vh" bg={VOMUX_PURPLE} mt="5vh" spacing="2vh">
        <Editor
          height="70vh"
          width="90vw"
          language={language}
          value={codeText}
          onChange={handleEditorChange}
          options={{
            fontSize: "22",
            snippetSuggestions: true,
          }}
          theme="vs-dark"
        />
        <ReactTerminal
          autoFocus={false}
          theme={{
            background: "#141313",
            promptSymbolColor: VOMUX_BLUE,
            commandColor: "#fcfcfc",
            outputColor: "#fcfcfc",
            errorOutputColor: "#ff89bd",
            fontSize: "1.5rem",
            spacing: "1%",
            fontFamily: "monospace",
            width: "90vw",
            height: "30vh",
          }}
        />
      </VStack>
    </Box>
  )
}

export default InterviewSession
