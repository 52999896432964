const LANGUAGES = {
  python: "Python",
  cpp: "C++",
  csharp: "C#",
  // css: "CSS",
  go: "Golang",
  // html: "HTML",
  java: "Java",
  javascript: "JavaScript",
  // mysql: "MySQL",
  perl: "Perl",
  php: "PHP",
  // pgsql: "PostgreSQL",
  objective: "Objective-C",
  r: "R",
  ruby: "Ruby",
  scala: "Scala",
  swift: "Swift",
  typescript: "TypeScript",
}

export default LANGUAGES
